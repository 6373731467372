import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from '../../../AuthContext/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faRocket, faHome, faRecordVinyl, faBroadcastTower, faCog, faKey, faBook, faCode, faShieldAlt, faBox, faCloud, faVideo, faChartLine, faMobileAlt,
  faGraduation, faGraduationCap, faPuzzlePiece
 } from '@fortawesome/free-solid-svg-icons'; // Import necessary Font Awesome icons
import './MainNavigation.css';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { faCodepen } from '@fortawesome/free-brands-svg-icons';


const MainNavigation = () => {

  const [isOpen, setIsOpen] = useState(false); // State for toggling the mobile menu
  const [showSubLinks, setShowSubLinks] = useState(true); // State for toggling sub-links
  const [subLinkName, setSubLinkName] = useState(''); // State for the sub-link name
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [activeLink, setActiveLink] = useState(Cookies.get('activeLink') ? Cookies.get('activeLink') : 'home');

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // Define icons for sub-links categories
    const subLinkIcons = {
      MediaSFU: faHome,
      Recording: faRecordVinyl,
      Streaming: faBroadcastTower,
      'Recommended Settings': faCog,
      'API Keys': faKey,
      Documentation: faBook,
      'SandBox (SDK)': faCode,
      Security: faShieldAlt,
      Prebuilt: faBox,
      'Cloud Recording': faCloud,
      'Video Codecs': faVideo,
      Scaling: faChartLine,
      'React SDK': faCodepen,
      'React Native SDK': faMobileAlt,
      'Flutter SDK': faCodepen,
      'Vue & Angular SDK': faCodepen,
      'Basics': faGraduationCap,
      'Components': faPuzzlePiece,
    };


  const setActiveLinked = (link) => {
    setActiveLink(link);

    Cookies.set('activeLink', link, { expires: 7 });
  };


  useEffect(() => {
    //set active link based on current url
    if (activeLink) {
      Cookies.set('activeLink', activeLink, { expires: 7 });
    }
  }, [activeLink]);

  useEffect(() => {

    if (Cookies.get('activeLink')) {
      setActiveLink(Cookies.get('activeLink'));
    }
  }, [Cookies.get('activeLink')]);

  useEffect(() => {

    let defaultNames = ['home', 'demos', 'features', 'developers', 'pricing', 'forums', 'support', 'docs', 'dashboard', 'login', 'signup', 'sandbox', 'documentation', 'security', 'prebuilt',
      'terms-of-service', 'privacy-policy', 'contact-us', 'about-us', 'recording', 'api-keys', 'cloud-recording', 'scaling', 'recommended-settings', 'video-codecs', 'basics', 'components',
    ];

    if (window.location.pathname === '/') {
      setActiveLink('home');
    } else if (defaultNames.includes(window.location.pathname.substring(1))) {
      setActiveLink(window.location.pathname.substring(1));
    } else {
      setActiveLink('home');
    }


  }, []);


  const handleSignOut = (event) => {
    event.preventDefault();

    // Add any sign-out logic you need here
    // For example, clearing cookies, logging out on the server, etc.
    auth.logout(); // Clear the context

    // After signing out, you can redirect the user to another page
    setActiveLink('login');
    Cookies.set('activeLink', 'login', { expires: 7 });
    history.push('/login'); // Redirect to the signout page

  };

  const toggleSubLinks = (subLink) => {
    setSubLinkName(subLink);

    // setShowSubLinks(!showSubLinks);
  };


  // Sample sub-links data
  const subLinksData = {
    home: ['MediaSFU', 'Recording', 'Streaming', 'API Keys'],
    developers: ['Documentation', 'Basics', 'Components',  "SandBox (SDK)", 'React SDK', 'Flutter SDK', 'Prebuilt', 'React Native SDK', 'Vue & Angular SDK (Oct. 2024)', 'Security', 'Cloud Recording', 'Video Codecs', 'Scaling', 'Recommended Settings'],
  };

  //format the sublinks
  const formatSubLinks = {
    'MediaSFU': '/',
    'Recording': '/recording',
    'Streaming': '/streaming',
    'API Keys': '/api-keys',
    'Documentation': '/documentation',
    'Basics': '/basics',
    'Components': '/components',
    'SandBox (SDK)': '/sandbox',
    'React SDK': 'https://github.com/MediaSFU/MediaSFU-ReactJS',
    'Flutter SDK': 'https://pub.dev/packages/mediasfu_sdk',
    'Vue & Angular SDK': '/',
    'Prebuilt': '/prebuilt',
    'React Native SDK': 'https://github.com/MediaSFU/MediaSFU',
    'Security': '/security',
    'Prebuilt': '/prebuilt',
    'Cloud Recording': '/cloud-recording',
    'Video Codecs': '/video-codecs',
    'Scaling': '/scaling',
    'Recommended Settings': '/recommended-settings',


  }



  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light mainNavigation">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/images/logo60.png" />
        </a>
        <button
          className={`navbar-toggler ${isOpen ? 'collapsed' : ''}`}
          type="button"
          onClick={toggleNavbar}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
          <ul className="navbar-nav navbar-nav-right" onMouseLeave={() => toggleSubLinks("")}>
            <li className="new-navs nav-item" onMouseEnter={() => toggleSubLinks('home')} >
              <div className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} href="/" onClick={() => setActiveLinked('home')}>
                HOME
                {subLinkName == 'home' && showSubLinks && subLinksData.home && (
                  <div className="sub-links-card">
                    <div className="row">
                      {subLinksData.home.map((subLink) => (
                        <div key={subLink} className="col-sm-6 col-lg-6 mb-2 sub-links-list">
                          <FontAwesomeIcon icon={subLinkIcons[subLink]} className="mr-2" />
                          <a href={formatSubLinks[subLink]}>{subLink}</a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'demos' ? 'active' : ''}`} href="/demos" onClick={() => setActiveLinked('demos')}>
                DEMOS
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'features' ? 'active' : ''}`} href="/features" onClick={() => setActiveLinked('features')} >
                FEATURES
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('developers')} >
              <div className={`new-navs nav-link ${activeLink === 'developers' ? 'active' : ''}`} href="/developers" onClick={() => setActiveLinked('developers')}>
                DEVELOPERS
                {subLinkName == 'developers' && showSubLinks && subLinksData.developers && (
                  <div className="sub-links-card">
                    <div className="row">
                      {subLinksData.developers.map((subLink) => (
                        <div key={subLink} className="col-sm-6 col-lg-6 mb-2 sub-links-list">
                          <FontAwesomeIcon icon={subLinkIcons[subLink]} className="mr-2" />
                          <a href={formatSubLinks[subLink]}>{subLink}</a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'pricing' ? 'active' : ''}`} href="/pricing" onClick={() => setActiveLinked('pricing')} >
                PRICING
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'forums' ? 'active' : ''}`} href="/forums" onClick={() => setActiveLinked('forums')}>
                FORUMS
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'support' ? 'active' : ''}`} href="/support" onClick={() => setActiveLinked('support')}>
                SUPPORT
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              <a className={`nav-link ${activeLink === 'docs' ? 'active' : ''}`} href="/docs" onClick={() => setActiveLinked('docs')}>
                DOCS
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks('')}>
              {auth.token ? (
                <div className="d-flex">
                  <a className={`nav-link ${activeLink === 'dashboard' ? 'active' : ''}`} href="/dashboard" onClick={() => setActiveLinked('dashboard')} >
                    DASHBOARD
                  </a>

                  <a className="nav-link" href="/signout" onClick={handleSignOut}>
                    SIGN OUT
                  </a>
                </div>
              ) : (
                <div className="d-flex" >
                  <a className={`nav-link ${activeLink === 'login' ? 'active' : ''}`} href="/login" onClick={() => setActiveLinked('login')}>
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" size="lg" />
                    LOGIN
                  </a>
                  <a className={`nav-link btn btn-secondary ${activeLink === 'start' ? 'active' : ''}`} href="/signup" onClick={() => setActiveLinked('signup')}>
                    <FontAwesomeIcon icon={faRocket} className="mr-2" size="lg" />
                    START
                  </a>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;



